import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  BOXES = [
    {id: 1, header: 'Advocacy for Employees', link: 'employees'},
    {id: 2, header: 'Advocacy for Employers', link: 'employers'},
  ];
  constructor() { }

  ngOnInit() {
  }



}
