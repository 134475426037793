import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  // whether or not the nav menu is open on small screens
  navbarOpen = true;

  constructor() {
  }

  ngOnInit() {
    console.log("navbar open??:"+this.navbarOpen);
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }



}
