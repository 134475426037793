import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private titleService: Title, private metaTagService: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('North Shore Employment Law Advocacy');
    this.metaTagService.updateTag({name: 'description', content: '"We advocate for you in employment matters. Learn more about us!"'});
  }

}
