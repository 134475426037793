import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privatemediation',
  templateUrl: './privatemediation.component.html',
  styleUrls: ['./privatemediation.component.css']
})
export class PrivatemediationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
